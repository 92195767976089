import React from 'react';
import _ from 'lodash';
import debounce from 'lodash/debounce';

import { Params as PaginateParams } from '@api/routes/people/paginate/params';
import { people as peopleApi } from 'src/store/api/people';
import Icon from 'src/components/common/Icon';

import css from './Search.module.scss';
import { QueryParamsType } from '..';

interface Props {
  onSearch: (v: string) => void;
  filters: QueryParamsType;
}

const PLACEHOLDER = 'חפשו לפי שם';

const Search: React.FC<Props> = ({ onSearch, filters }) => {
  const [searchString, setSearchString] = React.useState<string>(filters.search);
  const [innerFilters, setInnerFilters] = React.useState<QueryParamsType>(filters);
  // console.info('Search filters', innerFilters);
  const { data, isFetching } = peopleApi.endpoints.paginatePeople.useQuery({ ...(innerFilters as PaginateParams) });

  const suggestionsData = data?.success ? data.data.items : [];
  const exactSuggestions = _.filter(suggestionsData, (item) =>
    `${item.firstName} ${item.lastName}`.includes(searchString),
  );
  // const filteredData = _.filter(suggestionsData, (item) => {
  //   const str = `${item.firstName} ${item.lastName}`.split(searchString)[1];
  //   if (str && str.length > 0) {
  //     return item;
  //   }
  // });
  // const suggestionItem = filteredData[0];

  // const suggestionString = (item: any) => {
  //   return `${item.firstName} ${item.lastName}`.split(searchString)[1];
  // };

  React.useEffect(() => {
    setInnerFilters(filters);
    setSearchString(filters.search);
  }, [filters]);

  React.useEffect(() => {
    if (filters.search !== searchString && searchString == '') {
      // reset search
      onSearchBtnClick();
    }
    if (filters.search !== searchString) {
      onDebouncedSearch(searchString);
    }
  }, [searchString, filters]);

  const onSearchBtnClick = () => {
    onSearch(searchString.trim());
  };

  const onClearBtnClick = () => {
    setSearchString('');
    onSearch('');
  };

  const keyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      onSearchBtnClick();
    }

    // if (e.key === 'ArrowLeft') {
    //   setSearchString(`${searchString}${suggestionString(suggestionItem)}`);
    // }
  };

  const onDebouncedSearch = React.useCallback(
    debounce((searchString: string) => {
      setInnerFilters({
        ...innerFilters,
        search: searchString.trim(),
        offset: 0,
      });
    }, 1000),
    [setInnerFilters],
  );

  const onSuggestionClick = (firstName: string, lastName: string) => {
    setSearchString(`${firstName} ${lastName}`);
    onSearch(`${firstName} ${lastName}`);
  };

  return (
    <div className={css.searchContainer}>
      <div className={css.searchWrapper}>
        <div className={css.inputWrapper}>
          <input
            type="text"
            value={searchString}
            placeholder={PLACEHOLDER}
            onChange={(e) => setSearchString(e.target.value)}
            onKeyDown={keyDown}
          />

          {/* {searchString.length > 0 && (
            <>
              <span className={css.searchString}>{searchString}</span>
              <span className={css.suggestion}>
                {suggestionItem && (
                  <>
                    {searchString}
                    {suggestionString(suggestionItem)}
                  </>
                )}
              </span>
            </>
          )} */}
        </div>

        {searchString.length > 0 ? (
          <div className={css.clearBtn} onClick={onClearBtnClick}>
            <Icon type="clear" />
          </div>
        ) : (
          <div className={css.searchBtn} onClick={onSearchBtnClick}>
            <Icon type="search" />
          </div>
        )}
      </div>
      {searchString.length > 2 && exactSuggestions.length > 0 && filters.search !== searchString && (
        <div className={css.suggestions}>
          <div className={css.suggestionsList}>
            {_.map(exactSuggestions, (sg) => (
              <div className={css.item} key={sg._id} onClick={() => onSuggestionClick(sg.firstName, sg.lastName)}>
                {sg.firstName} {sg.lastName}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;
