import React from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider, CssBaseline } from '@mui/material';

import 'src/styles/index.scss';
import { theme } from 'src/theme';
import Head from 'src/components/common/Head';

const PageLayout = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Head />
        <Outlet />
      </ThemeProvider>
    </>
  );
};

export default PageLayout;
