import qs from 'qs';

import {
  ROUTE as PAGINATE_ROUTE,
  Params as PaginateParams,
  Result as PaginateResult,
} from '@api/routes/people/paginate/params';
import { ROUTE as GET_PERSON_ROUTE, Params as GetParams, Result as GetResult } from '@api/routes/people/get/params';
import { ApiResponse } from '@api/types';

import api from '..';

export const people = api.injectEndpoints({
  endpoints: (builder) => ({
    paginatePeople: builder.query<ApiResponse<PaginateResult>, PaginateParams>({
      query: (params) => ({
        method: 'GET',
        url: `${PAGINATE_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [...result.data.items.map(({ _id }) => ({ type: 'PEOPLE', _id } as const)), { type: 'PEOPLE', id: 'ALL' }]
          : [{ type: 'PEOPLE', id: 'ALL' }],
    }),
    getPerson: builder.query<ApiResponse<GetResult>, { id: string }>({
      query: (params) => ({
        method: 'GET',
        url: GET_PERSON_ROUTE.replace(':id', params.id),
      }),
      providesTags: (result) => (result?.success ? [{ type: 'PERSON', id: 'ALL' }] : [{ type: 'PERSON', id: 'ALL' }]),
    }),
  }),
  overrideExisting: false,
});
