import React from 'react';
import { useResponsive } from 'src/hooks/responsive';
import LinkButton from '../LinkButton';

import css from './Footer.module.scss';

const Footer: React.FC = () => {
  const policy = 'מדיניות פרטיות';
  const terms = 'תנאי שימוש';
  const termsLink = 'https://www.ynet.co.il/article/bkswwa3sn';
  const policyLink = 'https://www.ynet.co.il/article/h1oypwysn';
  const [isDesktop] = useResponsive(['DESKTOP']);

  return (
    <footer className={css.footer}>
      {!isDesktop && <LinkButton />}
      <div className={css.termsAndPolicy}>
        <a href={policyLink} target="_blank" rel="noopener noreferrer" className={css.link}>
          {policy}
        </a>
        <span className={css.devider} />
        <a href={termsLink} target="_blank" rel="noopener noreferrer" className={css.link}>
          {terms}
        </a>
      </div>
    </footer>
  );
};

export default Footer;
