import React from 'react';

import css from './LinkButton.module.scss';

type Props = {
  className?: string;
};

const LinkButton: React.FC = ({ className = '' }: Props) => {
  const label = 'לקובץ הנתונים המלא';
  const link = 'https://www.ynet.co.il/news/category/51693';

  return (
    <a href={link} target="_blank" rel="noopener noreferrer" className={`${css.link} ${className}`}>
      {label}
    </a>
  );
};

export default LinkButton;
