import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import cn from 'classnames';

import { routes } from 'src/constants';
import { useResponsive } from 'src/hooks/responsive';
import { share, Share, ShareType } from 'src/utils/share';
import Icon from '../Icon';
import AccessibilityIcon from './AccessibilityIcon';

import css from './Header.module.scss';

interface Props {
  isMain?: boolean;
}

const TEXTS = {
  share: 'שתפו את העמוד',
  email: `לשליחת תמונות
  ופרטים - צרו קשר`,
  siteMainText: {
    red: 'הנרצחים והנופלים',
    transparent: 'כתבו לזכרם',
  },
  backButton: 'לעמוד הראשי >',
};

const Header: React.FC<Props> = ({ isMain = false }) => {
  const [isDesktop] = useResponsive('DESKTOP');
  const navigate = useNavigate();

  const emailLink = 'https://www.ynet.co.il/redmail';
  const ynetLink = 'https://www.ynet.co.il/';

  const onSocialBtnClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const type = e.currentTarget.dataset.id as ShareType;
    share({ type });

    (window as any).dataLayer.push({
      event: 'GA_Events',
      Category: 'Share',
      Action: type,
      Label: 'iron swords memorial page',
    });
  };

  return (
    <header className={css.header}>
      <div className={css.logos}>
        <a href={ynetLink} rel="noopener noreferrer" className={css.ynetLink}>
          <Icon type="logo" />
        </a>
        {!isMain && isDesktop && (
          <Link to={routes.ROOT}>
            <div className={css.siteLogo}>
              <span className={css.red}>{TEXTS.siteMainText.red}</span>
              <span>{TEXTS.siteMainText.transparent}</span>
            </div>
          </Link>
        )}
      </div>
      <div className={cn(css.actions, !isMain && css.withBackBtn)}>
        {!isMain && (
          <button
            className={css.backButton}
            type="button"
            onClick={() => navigate('/?search=&sort%5BlastName%5D=1&limit=102&offset=0')}
          >
            {TEXTS.backButton}
          </button>
        )}
        <div className={css.socials}>
          <span className={css.text}>{TEXTS.share}</span>
          <button data-id={Share.WhatsApp} type="button" onClick={onSocialBtnClick} className={css.shareButton}>
            <Icon type="whatsapp" />
          </button>
          <button data-id={Share.Facebook} type="button" onClick={onSocialBtnClick} className={css.shareButton}>
            <Icon type="facebook" />
          </button>
          <button data-id={Share.Twitter} type="button" onClick={onSocialBtnClick} className={css.shareButton}>
            <Icon type="twitter" />
          </button>
        </div>
        <a href={emailLink} target="_blank" rel="noopener noreferrer" className={css.emaiLink}>
          <Icon type="email" />
          {(isMain || (!isMain && isDesktop)) && <span>{TEXTS.email}</span>}
        </a>
        {isDesktop && <AccessibilityIcon />}
      </div>
    </header>
  );
};

export default Header;
