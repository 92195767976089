import React from 'react';
import _ from 'lodash';
import cn from 'classnames';
import { useOnClickOutside } from 'src/hooks/click-uotside';
import Icon from 'src/components/common/Icon';
import { SortModel } from '..';

import css from './SortDropdown.module.scss';

interface Props {
  onChange: (v: any) => void;
  initialValue: SortModel;
}

const SORT_OPTIONS = [
  {
    label: 'תאריך פרסום (מהחדש)',
    field: 'addedDate',
    sortDirection: '-1',
  },
  {
    label: 'תאריך פרסום (מהישן)',
    field: 'addedDate',
    sortDirection: '1',
  },
  {
    label: `לפי א'-ב'`,
    field: 'lastName',
    sortDirection: '1',
  },
];

const SortDropdown: React.FC<Props> = ({ onChange, initialValue }) => {
  const [isOpen, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState(SORT_OPTIONS[0].label);
  const dropdownRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const option = _.find(SORT_OPTIONS, { sortDirection: initialValue?.addedDate?.toString() });
    option ? option.label !== selected && setSelected(option.label) : setSelected(SORT_OPTIONS[2].label);
  }, [initialValue]);

  const onSelect = (e: React.MouseEvent<HTMLDivElement>) => {
    const { field, direction, label } = e.currentTarget.dataset;
    if (label && field) {
      setSelected(label);
      onChange({ [field]: Number(direction) });
    }
    setOpen(false);
  };

  useOnClickOutside<HTMLDivElement>(dropdownRef, () => setOpen(false));

  return (
    <div className={css.sortDropdown}>
      <div className={css.sortDropdownInner} ref={dropdownRef}>
        <div className={css.selected} onClick={() => setOpen(!isOpen)}>
          {selected}
          <Icon type="chevron-down" className={css.chevronIcon} />
        </div>
        <div className={cn(css.list, isOpen && css.isOpen)}>
          {_.map(SORT_OPTIONS, (opt) => {
            if (opt.label !== selected) {
              return (
                <div
                  className={css.item}
                  data-field={opt.field}
                  data-label={opt.label}
                  data-direction={opt.sortDirection}
                  key={opt.label}
                  onClick={onSelect}
                >
                  {opt.label}
                </div>
              );
            }
          })}
        </div>
      </div>
    </div>
  );
};

export default SortDropdown;
