export const REDUX_STORAGE_KEY = '__REDUX_STORAGE';
export const CAN_USE_DOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const SHARE_CONSTANTS = {
  TWITTER: 'Twitter',
  FACEBOOK: 'Facebook',
  MAIL: 'E-mail',
  WHATS_APP: 'whatsApp',
};

export const DOMAIN_URL = 'https://kipur.n12.co.il';
export const IMGIX_URL = 'https://n12kipur.imgix.net';
