import React from 'react';

import css from './Icon.module.scss';

type Props = {
  type: string;
  className?: string;
  width?: number;
  height?: number;
  color?: string;
};

const Icon: React.FC<Props> = ({
  type,
  className = '',
  width = undefined,
  height = undefined,
  color = '',
  ...iconProps
}: React.ComponentProps<typeof Icon>) => {
  let icon;
  const svgProps = {
    width,
    height,
    viewBox: '0 0 24 24',
    xmlns: 'http://www.w3.org/2000/svg',
    className: css.svg,
  };

  switch (type) {
    case 'logo':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 131 55" width="131" height="55" fill="none">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M83.73 42.192V18.853c0-5.119-3.254-8.532-8.337-8.532-3.525 0-6.372 1.366-9.219 4.71l-.677-3.071a1.46 1.46 0 0 0-1.627-1.229h-2.033a1.468 1.468 0 0 0-1.559 1.433v30.028a1.57 1.57 0 0 0 1.56 1.5h3.117a1.51 1.51 0 0 0 1.491-1.5v-18.5c0-4.574 3.185-8.055 7.252-8.055 2.78 0 3.863 1.366 3.863 4.574v21.981a1.57 1.57 0 0 0 1.56 1.5h3.117a1.51 1.51 0 0 0 1.491-1.5zm22.52-18.083h-12c.678-7.1 3.8-8.8 6.236-8.8 4.2 0 5.625 4.437 5.761 8.8m6.3 3.139c0-12.491-5.422-16.927-12.268-16.927-7.523 0-12.47 6.484-12.47 16.722 0 11.194 5.218 17 14.436 17 2.884.13 5.758-.407 8.4-1.57a1.402 1.402 0 0 0 .881-1.843l-.735-2.188a1.326 1.326 0 0 0-1.83-.956 19.648 19.648 0 0 1-6.372 1.161c-5.964 0-8.065-3.549-8.336-9.9h16.808a1.506 1.506 0 0 0 1.491-1.5m17.716 14.472-.339-2.115a1.275 1.275 0 0 0-1.559-1.093 11.79 11.79 0 0 1-2.1.273c-1.559 0-2.3-.819-2.3-2.661V15.576h3.864a1.456 1.456 0 0 0 1.329-.936c.068-.18.1-.372.094-.564v-1.844a1.464 1.464 0 0 0-.398-1.047 1.448 1.448 0 0 0-1.025-.453h-3.87v-8.6c0-1.228-.882-1.3-1.9-1.024l-3.05.888a1.682 1.682 0 0 0-1.149 1.774v6.962h-3.66a1.414 1.414 0 0 0-1.424 1.5v1.844a1.419 1.419 0 0 0 .385 1.06 1.415 1.415 0 0 0 1.039.44h3.66v21.57c0 4.573 2.236 6.893 7.049 6.893a15.657 15.657 0 0 0 4.337-.546 1.368 1.368 0 0 0 .904-.661 1.382 1.382 0 0 0 .113-1.113z"
            clipRule="evenodd"
          />
          <path fill="#DE1A1A" d="M.001 27.016A27.014 27.014 0 1 0 46.118 7.913 27.014 27.014 0 0 0 0 27.016z" />
          <path
            fill="#FEFEFE"
            fillRule="evenodd"
            d="M38.443 11.869a1.02 1.02 0 0 0-.982-1.418h-3.273a1.413 1.413 0 0 0-1.473 1.145l-4.038 16.477c-.382 1.528-.655 2.892-.873 3.874a16.869 16.869 0 0 0-.491-3.329L22.73 11.651a1.516 1.516 0 0 0-1.528-1.2h-3.6a.978.978 0 0 0-.982 1.364l6.983 21.878a1.367 1.367 0 0 0 1.473 1.146h1.855a17.92 17.92 0 0 1-.928 3.273 7.281 7.281 0 0 1-3.71 4.856l-.763.381a1.32 1.32 0 0 0-.655 1.692l1.146 2.237a1.23 1.23 0 0 0 1.637.6l1.2-.546c2.89-1.309 5.019-3.819 6.11-7.856l7.475-27.607z"
            clipRule="evenodd"
          />
        </svg>
      );
      break;
    case 'whatsapp':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width="34" height="34" fill="none">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M13.79 9.119c-.318-.846-.686-.785-.93-.794-.244-.01-.46-.005-.79 0a1.608 1.608 0 0 0-1.137.478 4.653 4.653 0 0 0-1.598 3.415 8.054 8.054 0 0 0 1.55 4.364c.198.285 2.725 4.722 6.923 6.547 4.198 1.824 4.22 1.268 4.99 1.226.77-.04 2.518-.914 2.905-1.872a3.45 3.45 0 0 0 .319-1.97c-.099-.178-.374-.292-.785-.514-.411-.222-2.434-1.307-2.813-1.457-.379-.15-.656-.232-.952.172-.296.403-1.134 1.311-1.389 1.58-.254.27-.5.294-.913.07a11.465 11.465 0 0 1-3.293-2.19 12.566 12.566 0 0 1-2.227-2.966c-.229-.429 0-.643.217-.842.195-.18.437-.47.656-.705.18-.204.33-.433.445-.68a.76.76 0 0 0-.007-.73c-.093-.209-.854-2.287-1.171-3.132z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            d="M17.537 2.39a14.006 14.006 0 0 1 9.93 4.079 13.818 13.818 0 0 1 0 19.652 14.007 14.007 0 0 1-9.93 4.08c-2.372 0-4.706-.595-6.788-1.732l-.897-.493-.975.31-4.589 1.457 1.445-4.266.358-1.056-.58-.953A13.816 13.816 0 0 1 7.607 6.47a14.004 14.004 0 0 1 9.93-4.08m0-2.389A16.359 16.359 0 0 0 1.114 16.296 16.127 16.127 0 0 0 3.47 24.71L.507 33.453 9.6 30.564A16.334 16.334 0 1 0 17.537 0v.001z"
          />
        </svg>
      );
      break;
    case 'facebook':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 34" width="34" height="34" fill="none">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M18.12 12.042c0-.658.316-.928.631-.928h2.018V8.116h-2.742a3.52 3.52 0 0 0-3.674 3.76v1.33h-1.671v3.346h1.673v8.785h3.765v-8.786h2.28l.111-1.307.204-2.04H18.12v-1.162z"
            clipRule="evenodd"
          />
          <path
            fill="#fff"
            d="M16.727 2.535A14.192 14.192 0 0 0 6.69 26.76 14.192 14.192 0 1 0 26.76 6.69a14.098 14.098 0 0 0-10.034-4.156zm0-2.535a16.726 16.726 0 1 1-.002 33.452A16.726 16.726 0 0 1 16.727 0z"
          />
        </svg>
      );
      break;
    case 'email':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 26" width="32" height="26" fill="none">
          <path
            stroke="#93150F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.25"
            d="M30.625 5.125v15.75a3.375 3.375 0 0 1-3.375 3.375H4.75a3.375 3.375 0 0 1-3.375-3.375V5.125m29.25 0A3.375 3.375 0 0 0 27.25 1.75H4.75a3.375 3.375 0 0 0-3.375 3.375m29.25 0v.364a3.374 3.374 0 0 1-1.605 2.875l-11.25 6.922a3.375 3.375 0 0 1-3.54 0L2.98 8.365a3.375 3.375 0 0 1-1.605-2.874v-.366"
          />
        </svg>
      );
      break;

    case 'twitter':
      icon = (
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M16.727 2.534A14.192 14.192 0 0 0 6.69 26.761a14.192 14.192 0 1 0 20.07-20.07 14.098 14.098 0 0 0-10.034-4.157zm0-2.534a16.726 16.726 0 1 1-.002 33.451A16.726 16.726 0 0 1 16.727 0z"
            fill="#fff"
          />
          <path
            d="M26.361 11.185c-.7.31-1.441.513-2.2.603.8-.48 1.398-1.233 1.684-2.121a7.665 7.665 0 0 1-2.434.93 3.834 3.834 0 0 0-6.532 3.496 10.883 10.883 0 0 1-7.903-4.005 3.835 3.835 0 0 0 1.186 5.119 3.821 3.821 0 0 1-1.736-.48v.047a3.836 3.836 0 0 0 3.076 3.76 3.856 3.856 0 0 1-1.733.066 3.836 3.836 0 0 0 3.583 2.663 7.696 7.696 0 0 1-5.676 1.587 10.845 10.845 0 0 0 5.876 1.724c7.052 0 10.908-5.842 10.908-10.909 0-.165-.004-.331-.011-.496a7.793 7.793 0 0 0 1.912-1.984z"
            fill="#fff"
          />
        </svg>
      );
      break;
    case 'accessibility':
      icon = (
        <svg width="43" height="43" viewBox="0 0 43 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21.365 7.55902C21.8978 7.55922 22.4185 7.71739 22.8614 8.01353C23.3043 8.30967 23.6495 8.73048 23.8532 9.22276C24.0569 9.71505 24.1102 10.2567 24.0061 10.7792C23.902 11.3017 23.6454 11.7817 23.2686 12.1583C22.8918 12.535 22.4117 12.7915 21.8892 12.8953C21.3666 12.9992 20.825 12.9458 20.3328 12.7419C19.8406 12.5379 19.4199 12.1926 19.1239 11.7496C18.828 11.3066 18.67 10.7858 18.67 10.253C18.67 9.89916 18.7397 9.54876 18.8751 9.22184C19.0106 8.89492 19.2091 8.5979 19.4594 8.34772C19.7097 8.09755 20.0068 7.89913 20.3337 7.7638C20.6607 7.62848 21.0111 7.55889 21.365 7.55902ZM33.491 16.99H25.407V34.504H22.712V26.42H20.017V34.503H17.323V16.99H9.23898V14.295H33.49L33.491 16.99Z"
            fill="white"
          />
          <path
            d="M21.365 3.186C17.8357 3.18585 14.3856 4.2323 11.451 6.19301C8.51641 8.15372 6.22918 10.9406 4.87855 14.2013C3.52791 17.462 3.17454 21.0499 3.86312 24.5114C4.5517 27.9729 6.2513 31.1525 8.74698 33.648C10.4014 35.3165 12.369 36.6418 14.537 37.5479C16.7049 38.454 19.0305 38.923 21.3802 38.928C23.7298 38.9329 26.0573 38.4738 28.2291 37.5769C30.4009 36.68 32.3741 35.3631 34.0356 33.7016C35.6971 32.0401 37.014 30.0669 37.9109 27.8951C38.8078 25.7234 39.2669 23.3958 39.2619 21.0462C39.257 18.6965 38.788 16.371 37.8819 14.203C36.9758 12.0351 35.6505 10.0674 33.982 8.413C32.329 6.75079 30.3628 5.43294 28.197 4.53572C26.0313 3.63851 23.7092 3.17975 21.365 3.186ZM21.365 0C25.5243 0.000197779 29.5901 1.23375 33.0484 3.54468C36.5066 5.85561 39.2019 9.14011 40.7935 12.9829C42.385 16.8256 42.8013 21.054 41.9898 25.1334C41.1782 29.2127 39.1752 32.9598 36.2341 35.9008C33.2929 38.8418 29.5458 40.8446 25.4664 41.656C21.387 42.4674 17.1586 42.0508 13.3159 40.4591C9.47325 38.8674 6.18887 36.1719 3.87811 32.7135C1.56735 29.2552 0.333984 25.1893 0.333984 21.03C0.33425 15.4524 2.55013 10.1033 6.49418 6.15948C10.4382 2.21562 15.7874 -6.30517e-09 21.365 0Z"
            fill="white"
          />
        </svg>
      );
      break;
    case 'search':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37 37" width="37" height="37" fill="none">
          <path
            fill="#414141"
            d="m31.224 29.213-5-4.984a11.643 11.643 0 0 0 2.484-7.2 11.761 11.761 0 1 0-11.76 11.758 11.637 11.637 0 0 0 7.2-2.484l4.985 5a1.477 1.477 0 1 0 2.088-2.088l.003-.002zM8.128 17.026a8.821 8.821 0 1 1 17.642 0 8.821 8.821 0 0 1-17.642 0z"
          />
        </svg>
      );
      break;
    case 'up':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89 89" width="89" height="89" fill="none">
          <path
            fill="#DD1A1A"
            d="M44.5 89C69.077 89 89 69.077 89 44.5S69.077 0 44.5 0 0 19.923 0 44.5 19.923 89 44.5 89z"
          />
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="5"
            d="M56.7 44.351 44.351 32 32 44.351M44.351 56.7V32"
          />
        </svg>
      );
      break;
    case 'arrow-left':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 27" width="26" height="27" fill="none">
          <path
            stroke="#fff"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="4"
            d="M23.02 13.346H2.128m8.958 10.996L2.13 13.351l8.957-11"
          />
        </svg>
      );
      break;
    case 'close':
      icon = (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="m22.788 20.241-7.666-7.666 7.666-7.666a2.325 2.325 0 1 0-3.283-3.287L11.84 9.288 4.173 1.622A2.325 2.325 0 0 0 .885 4.91l7.666 7.666-7.67 7.665a2.248 2.248 0 0 0 0 3.288 2.31 2.31 0 0 0 3.287 0l7.666-7.666 7.671 7.666a2.336 2.336 0 0 0 3.796-.754 2.307 2.307 0 0 0-.513-2.534z"
            fill="#000"
          />
        </svg>
      );
      break;
    case 'clear':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
          <path
            d="M16.8445 19.8011L9.58333 12.2242L2.13155 20L0 17.7758L7.45178 10L0.381254 2.62206L2.51281 0.39783L9.58333 7.77577L17.0351 0L19.1667 2.22423L11.7149 10L18.976 17.5769L16.8445 19.8011Z"
            fill="#414141"
          />
        </svg>
      );
      break;
    case 'chevron-down':
      icon = (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
          <path
            d="M3.62398 5.35548C3.56033 5.29533 3.48546 5.24831 3.40364 5.2171C3.32182 5.18589 3.23466 5.1711 3.14712 5.17358C3.05959 5.17605 2.9734 5.19575 2.89347 5.23153C2.81355 5.26732 2.74146 5.3185 2.68131 5.38214C2.62117 5.44579 2.57415 5.52066 2.54294 5.60248C2.51173 5.6843 2.49694 5.77147 2.49941 5.859C2.50189 5.94654 2.52158 6.03273 2.55737 6.11265C2.59315 6.19257 2.64433 6.26467 2.70798 6.32481L8.70798 11.9915C8.83176 12.1085 8.99564 12.1737 9.16598 12.1737C9.33632 12.1737 9.5002 12.1085 9.62398 11.9915L15.6246 6.32481C15.6897 6.26506 15.7422 6.19299 15.7792 6.11277C15.8161 6.03255 15.8367 5.94579 15.8399 5.85753C15.843 5.76926 15.8286 5.68126 15.7974 5.59862C15.7663 5.51598 15.719 5.44036 15.6584 5.37615C15.5977 5.31193 15.5249 5.26041 15.4442 5.22456C15.3635 5.18872 15.2765 5.16927 15.1882 5.16735C15.0999 5.16542 15.0121 5.18107 14.9299 5.21336C14.8477 5.24566 14.7727 5.29396 14.7093 5.35548L9.16598 10.5901L3.62398 5.35548Z"
            fill="white"
          />
        </svg>
      );
      break;
    case 'candle':
      icon = (
        <svg width="46" height="86" viewBox="0 0 46 86" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_62_1836)">
            <path
              d="M46 46.7386C45.9417 46.1852 45.6669 45.6775 45.2357 45.327C44.8045 44.9765 44.252 44.8118 43.6999 44.8691H2.30012C1.74795 44.8118 1.19547 44.9765 0.764279 45.327C0.333093 45.6775 0.058299 46.1852 0 46.7386V85.9995H46V46.7386Z"
              fill="url(#paint0_linear_62_1836)"
            />
            <path
              d="M22.1121 40.9307C22.3897 41.0617 22.693 41.1299 22.9999 41.1301C22.5817 41.1278 22.1734 41.0021 21.8261 40.7686C20.1036 39.5003 18.693 37.8541 17.7018 35.9558C16.7107 34.0575 16.1654 31.9574 16.1074 29.8155C15.9433 23.0303 21.0803 16.5517 21.3016 16.2775C21.5017 16.0121 21.7604 15.7967 22.0574 15.6485C22.3545 15.5003 22.6817 15.4232 23.0135 15.4232C23.3453 15.4232 23.6726 15.5003 23.9696 15.6485C24.2667 15.7967 24.5254 16.0121 24.7254 16.2775C24.9467 16.5517 30.0838 23.0303 29.9197 29.8155C29.8613 31.9573 29.3157 34.0574 28.3246 35.9557C27.3335 37.8539 25.9232 39.5 24.2009 40.7686C23.8588 40.9965 23.4579 41.1196 23.0472 41.1227C23.3427 41.1204 23.6343 41.0549 23.9026 40.9307C24.3999 40.6814 36.4047 34.9481 36.8124 23.2322C37.2128 11.8278 24.9541 0.994391 24.4295 0.548188C24.0415 0.196976 23.5375 0.00244141 23.0147 0.00244141C22.492 0.00244141 21.988 0.196976 21.6 0.548188C21.0754 1.00187 8.81703 11.8353 9.21736 23.2322C9.61022 34.9606 21.6023 40.6939 22.1121 40.9307Z"
              fill="#DD1A1A"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_62_1836"
              x1="23"
              y1="44.8579"
              x2="23"
              y2="85.9995"
              gradientUnits="userSpaceOnUse"
            >
              <stop />
              <stop offset="1" stopOpacity="0" />
            </linearGradient>
            <clipPath id="clip0_62_1836">
              <rect width="46" height="86" fill="white" />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    default:
      break;
  }

  return (
    <i className={`${css.icon} ${className}`} {...iconProps}>
      {icon}
    </i>
  );
};

export default Icon;
