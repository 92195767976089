import React from 'react';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import cn from 'classnames';
import DOMPurify from 'dompurify';

import { CAN_USE_DOM } from 'src/constants/other';
import { Result as PersonType } from '@api/routes/people/get/params';
import { IModel as MemoryIModel } from '@api/models/memories/interface';
import { people as peopleApi } from 'src/store/api/people';
import { memories as memoriesApi } from 'src/store/api/memories';
import { useResponsive } from 'src/hooks/responsive';
import Header from 'src/components/common/Header';
import Head from 'src/components/common/Head';
import Icon from 'src/components/common/Icon';
import Popup from './Popup';

import defaultImage from 'src/assets/default-image.jpg';

import css from './Person.module.scss';

type MemoryType = Omit<MemoryIModel, '_id' | 'relatedTo'> & { _id: string; relatedTo: string };
const ITEMS_PER_PAGE = 20;

// const TEXTS = {
//   noMemoriesYet: 'עדיין לא נכתבו מילות פרידה',
// };

const Person = () => {
  const location = useLocation();
  const [isMobile] = useResponsive(['MOBILE']);
  const [isDesktop] = useResponsive(['DESKTOP']);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [memories, setMemories] = React.useState<any[]>([]);

  const id = location.pathname.replace('/', '');
  const { data, isFetching: isPersonFetching } = peopleApi.endpoints.getPerson.useQuery({ id });
  const person: PersonType | undefined = React.useMemo(() => (data?.success ? data.data : undefined), [data]);

  const [page, setPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(1);
  const { data: memoriesData } = memoriesApi.endpoints.paginateMemories.useQuery({
    filter: { relatedTo: id },
    limit: ITEMS_PER_PAGE,
    offset: (page - 1) * ITEMS_PER_PAGE,
  });

  React.useMemo(() => {
    if (memoriesData?.success) {
      setMemories([...memories, ...memoriesData.data.items]);
      return memoriesData.data.items;
    } else {
      return [];
    }
  }, [memoriesData]);

  React.useMemo(
    () => (memoriesData?.success ? setTotalPages(Math.ceil(memoriesData.data.page.total / ITEMS_PER_PAGE)) : 1),
    [memoriesData],
  );

  React.useEffect(() => {
    if (memories) {
      document.addEventListener('scroll', onScroll);
    }
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [memories]);

  const onScroll = () => {
    if (totalPages !== page && window.pageYOffset + window.innerHeight + 100 >= document.body.scrollHeight) {
      setPage(page + 1);
    }
  };

  const onClosePopup = () => {
    setOpenPopup(false);
  };

  const { firstName = '', lastName = '', role = '' } = data?.success ? data.data : {};
  const head = React.useMemo(
    () => ({
      og: {
        title: `${[role, firstName, lastName].join(' ')} - לזכרם של הנרצחים והנופלים במלחמה`,
        description: `ילדים וקשישים, נשים וגברים, אזרחים ואנשי כוחות הביטחון וההצלה: אלו הם האנשים שקיפחו את חייהם במתקפת הטרור של חמאס ובמלחמה שפרצה בעקבותיה. מילות הפרידה שנכתבו על ${[
          role,
          firstName,
          lastName,
        ].join(' ')}`,
      },
      seo: {
        title: `${[role, firstName, lastName].join(' ')} - לזכרם של הנרצחים והנופלים במלחמה `,
      },
      urlPath: id,
    }),
    [firstName, lastName, role],
  );

  // const noMemories = () => {
  //   return <p className={css.noMemories}>{TEXTS.noMemoriesYet}</p>;
  // };

  const articleLink = (link: string) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" className={css.articleLink}>
        <span>לכתבה המלאה</span>
        <Icon type="arrow-left" />
      </a>
    );
  };

  return (
    <>
      {[firstName, lastName, role].some(Boolean) && (
        <Head og={head.og} seo={{ title: head.seo.title }} urlPath={head.urlPath} />
      )}
      <Header />
      <div className={css.person}>
        <div className={cn(css.fixedContent, isPersonFetching && css.animation)}>
          <div className={css.photo}>
            <div className={css.imageWrap}>
              <img src={person?.imageUrl || defaultImage} alt={`${person?.role} ${person?.firstName}`} />
            </div>
            <button type="button" onClick={() => setOpenPopup(true)} className={css.popupBtn}>
              <span>להוספת מילות פרידה</span>
              <Icon type="arrow-left" />
            </button>
          </div>
          <div className={css.info}>
            <h1>{`${person?.role} ${person?.firstName} ${person?.lastName}`}</h1>
            <p className={css.additional}>
              {person?.age}
              {person?.age && person?.city && ', '} {person?.city}
            </p>
            {isDesktop && person?.story && <p className={css.story}>{person?.story}</p>}
            {/* {isDesktop && memories.length === 0 && noMemories()} */}
            {isDesktop && person?.articleLink && articleLink(person.articleLink)}
          </div>
        </div>

        <div className={css.scrolledContent}>
          {isMobile && person?.story && <p className={css.story}>{person?.story}</p>}
          {/* {isMobile && memories.length === 0 && noMemories()} */}
          {isMobile && person?.articleLink && articleLink(person.articleLink)}
          {CAN_USE_DOM &&
            _.map(memories, (memory: MemoryType) => (
              <div className={css.memory} key={memory._id}>
                <p className={css.author}>{DOMPurify.sanitize(memory?.author)}</p>
                <p>{DOMPurify.sanitize(memory?.memory)}</p>
              </div>
            ))}
        </div>
      </div>
      {openPopup && (
        <Popup
          id={person?._id}
          name={`${person?.role} ${person?.firstName} ${person?.lastName}`}
          onClose={onClosePopup}
        />
      )}
    </>
  );
};

export default Person;
