import React from 'react';
import { Helmet } from 'react-helmet-async';

import faviconUrl from 'src/assets/favicon/favicon.png';
import shareImgUrl from 'src/assets/share-img.png';
import shareImgTwitterUrl from 'src/assets/new-share.jpg';

interface Props {
  seo?: {
    title?: string;
    description?: string;
  };
  og?: {
    title?: string;
    description?: string;
    imageUrl?: string;
  };
  urlPath?: string;
  children?: React.ReactNode;
}

const Head = (props: Props) => {
  const { seo, og, urlPath } = props;
  // ynet-iron-swords-memories-dev.firebaseapp.com
  // https://names2023war.ynet.co.il
  const domain = 'https://names2023war.ynet.co.il';
  // const originDomain = !!(typeof window !== 'undefined' && window.document) ? window.location.href : '';
  const url = urlPath ? `${domain}/${urlPath}` : domain;
  const shareImgTwitterFullPath = `${domain}${shareImgTwitterUrl}`;
  const shareImgUrlFullPath = `${domain}${shareImgUrl}`;

  const SEO = {
    title: `נרצחי וחללי המלחמה: השמות והזכרונות`,
    description: `ילדים וקשישים, נשים וגברים, אזרחים ואנשי כוחות הביטחון וההצלה: אלו הם האנשים שקיפחו את חייהם במתקפת הטרור של חמאס ובמלחמה שפרצה בעקבותיה. כתבו כאן את מילות הפרידה שלכם`,
    ...seo,
  };
  const OG = {
    title: `נרצחי וחללי המלחמה: השמות והזכרונות`,
    description: `ילדים וקשישים, נשים וגברים, אזרחים ואנשי כוחות הביטחון וההצלה: אלו הם האנשים שקיפחו את חייהם במתקפת הטרור של חמאס ובמלחמה שפרצה בעקבותיה. כתבו כאן את מילות הפרידה שלכם`,
    ...og,
  };

  return (
    <Helmet>
      <title>{SEO.title}</title>
      <meta name="description" content={SEO.description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={url} />
      <meta property="og:site_name" content="Ynet Iron Swords" />
      <meta property="og:locale" content="he_IL" />
      <meta property="og:title" content={OG.title} />
      <meta property="og:description" content={OG.description} />
      <meta property="og:image" content={shareImgUrlFullPath} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:image" content={shareImgTwitterFullPath} />
      <link rel="icon" type="image/png" href={faviconUrl} />
      {props.children}
    </Helmet>
  );
};

export default Head;
