import React from 'react';

import { memories as memoriesApi } from 'src/store/api/memories';
import Icon from 'src/components/common/Icon';

import css from './Popup.module.scss';

interface Props {
  onClose: () => void;
  name?: string;
  id?: string;
}

const TEXTS = {
  title: 'כתבו מילות פרידה והעלו זיכרונות על ',
  placeholders: {
    textarea: 'מילים לזיכרון',
    textField: 'שם הכותב',
  },
  button: 'שליחה',
  terms: 'אין להעלות מידע הסותר את ',
  termsLinkTxt: 'תנאי השימוש',
  successScreen: {
    top: 'דברי הפרידה שכתבתם התקבלו',
    sub: 'ויתפרסמו בעמוד לאחר אישור המערכת',
  },
  openPopupText: 'להוספת מילות פרידה',
};
const termsLink = 'https://www.ynet.co.il/article/bkswwa3sn';

const Popup: React.FC<Props> = ({ id, name, onClose }) => {
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [errors, setErrors] = React.useState({ memory: '', author: '' });
  const [isSubmitted, setIsSubmitted] = React.useState(false);

  const [submitData, updateState] = memoriesApi.endpoints.addMemory.useMutation();

  React.useEffect(() => {
    (window as any).dataLayer.push({
      event: 'GA_Events',
      Category: 'iron swords memorial page',
      Action: TEXTS.openPopupText,
    });
  }, []);

  const onSubmit = () => {
    const memory = textareaRef.current?.value || '';
    const author = inputRef.current?.value || '';
    const errors = { memory: '', author: '' };

    if (!memory) {
      errors.memory = 'required';
    }

    if (!author) {
      errors.author = 'required';
    }

    if (memory && typeof memory !== undefined && author && typeof author !== undefined) {
      const data = { memory: memory, author: author, relatedTo: id || '' };
      submitData(data);
      (window as any).dataLayer.push({
        event: 'GA_Events',
        Category: 'iron swords memorial page',
        Action: 'לשליחה',
      });
      setIsSubmitted(true);
    }

    setErrors(errors);
  };

  return (
    <div className={css.popup}>
      <div className={css.formWrapper}>
        {isSubmitted ? (
          <div className={css.successScreen}>
            <Icon type="candle" />
            <p>{TEXTS.successScreen.top}</p>
            <p className={css.subText}>{TEXTS.successScreen.sub}</p>
          </div>
        ) : (
          <div className={css.formContent}>
            <h6 className={css.title}>{`${TEXTS.title} ${name}`}</h6>
            <form name="memory">
              <textarea
                name="memory"
                id="memory"
                cols={30}
                rows={10}
                ref={textareaRef}
                placeholder={TEXTS.placeholders.textarea}
                className={errors.memory && css.error}
              ></textarea>
              <div className={css.formRow}>
                <input
                  type="text"
                  name="author"
                  ref={inputRef}
                  placeholder={TEXTS.placeholders.textField}
                  className={errors.author && css.error}
                />
                <button type="button" className={css.saveButton} onClick={onSubmit}>
                  {TEXTS.button}
                </button>
              </div>
              <p className={css.terms}>
                {TEXTS.terms}
                <a href={termsLink} target="_blank" rel="noopener noreferrer">
                  {TEXTS.termsLinkTxt}
                </a>
              </p>
            </form>
          </div>
        )}
        <button type="button" className={css.closeButton} onClick={onClose}>
          <Icon type="close" />
        </button>
      </div>
    </div>
  );
};

export default Popup;
