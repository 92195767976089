import qs from 'qs';

import {
  ROUTE as PAGINATE_ROUTE,
  Params as PaginateParams,
  Result as PaginateResult,
} from '@api/routes/memories/paginate/params';
import { ROUTE as POST_ROUTE, Params as PostParams, Result as PostResult } from '@api/routes/memories/post/params';
import { ApiResponse } from '@api/types';

import api from '..';

export const memories = api.injectEndpoints({
  endpoints: (builder) => ({
    paginateMemories: builder.query<ApiResponse<PaginateResult>, PaginateParams>({
      query: (params) => ({
        method: 'GET',
        url: `${PAGINATE_ROUTE}?${qs.stringify(params)}`,
      }),
      providesTags: (result) =>
        result?.success
          ? [
              ...result.data.items.map(({ _id }) => ({ type: 'MEMORIES', _id } as const)),
              { type: 'MEMORIES', id: 'ALL' },
            ]
          : [{ type: 'MEMORIES', id: 'ALL' }],
    }),
    addMemory: builder.mutation<ApiResponse<PostResult>, PostParams>({
      query: (params) => ({
        method: 'POST',
        url: POST_ROUTE,
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});
